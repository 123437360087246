<template>
	<div
		v-if="course"
		class="course-module"
	>
		<div class="course-module__body">
			<div
				class="course-module__pic"
				:style="`background-image: url('${course.course.cover ? course.course.cover : 'https://lift-bf.ru/staticfiles/img/landscape-image.png'}')`"
			/>
			<div class="course-module__content">
				<div class="course-module__title">
					{{ course.course.name }}
				</div>
				<div class="course-module__stats">
					<div class="stat">
						<img
							:src="'/img/icons/icon-course-program-material.svg' | prodSrcImage"
							alt="Тип"
							class="stat__icon"
						>
						Курс
					</div>
					<div class="stat">
						<img
							:src="'/img/icons/icon__course-duration--dark.svg'| prodSrcImage"
							alt="Длительность"
							class="stat__icon"
						>
						{{ course.course.duration | durationFilter }}
					</div>
					<div class="stat">
						<img
							:src="'/img/icons/icon__course-list.svg'| prodSrcImage"
							alt="Календарь"
							class="stat__icon"
						>
						{{
							course.course.sections_course.length
								| toPlural("тема", "темы", "тем")
						}}
					</div>
				</div>
			</div>
			<div
				v-if="program.participant"
				class="course-module__action"
			>
				<a
					class="button orange sm"
					:href="linkToCourse()"
					target="__blank"
				>
					Продолжить курс
				</a>
			</div>
		</div>
		<div class="course-module__footer">
			<div
				:class="['course-module__toggle',{'course-module__toggle--open':isShow}] "
				@click="open"
			>
				{{ isShow ? "Скрыть" : "Показать" }} содержание
			</div>
			<slide-up-down
				:active="isShow"
				:duration="600"
			>
				<div
					class="course-module__description"
				>
					<div class="course-module__units">
						<div
							v-for="theme in course.course.sections_course"
							:key="theme.id"
							class="unit"
						>
							{{ theme.name }}
						</div>
					</div>
				</div>
			</slide-up-down>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	props: {
		course: {
			type: Object,
			default: () => {
			}
		},
		isShowLink: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			isShow: false
		};
	},
	computed: {
		...mapGetters('tb', {
			program: 'getProgram'
		}),
        ...mapGetters('user', {
            user: 'userInfo'
        }),
	},
	methods: {
		open() {
			this.isShow = !this.isShow;
		},
        linkToCourse() {
            return this.course && this.course.course && this.course.course.course_session_link;
        }
	}
};
</script>
