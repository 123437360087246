<template>
	<div>
		<div
			v-if="!isLoaded"
			class="preloader"
			style="margin-top: 100px"
		/>
		<div
			v-if="program && isLoaded"
		>
			<section class="section section-banner">
				<div class="content">
					<Breadcrumb :page-name="program.name" />
					<div
						class="banner"
						:style="program.image | bgrDynamicImageCover"
					>
						<div class="banner__content">
							<h2 class="title-mono">
								программа
							</h2>
							<h1 class="title-section">
								{{ program.name }}
							</h1>
							<div class="banner__text">
								{{ program.why }}
							</div>
							<div class="banner__stats">
								<div class="banner__members">
									{{ program.total_listeners }}
								</div>
								<div class="banner__duration">
									{{ program.duration | durationFilter }}
								</div>
							</div>
							<div
								v-if="program.progress >= 0 && program.progress !== null"
								class="progress-banner"
							>
								<div
									class="progress-banner__line"
									:style="`width:${program.progress}%`"
								/>
							</div>
							<div class="banner__button">
								<BaseButton
									class="button md"
									:blue="!program.participant && isLogged || isLoadingRegistred"
									:orange="program.participant || !isLogged"
									:shadow-border="!program.participant"
									md
									:class="{ 'preloader--on': isLoadingRegistred }"
									@clickButton="start"
								>
									<span v-if="!isLoadingRegistred">
										{{ buttonSendText }}</span>
									<div
										v-else
										class="button-preloader-icons"
									>
										<div class="button-preloader-icon" />
										<div class="button-preloader-icon" />
										<div class="button-preloader-icon" />
									</div>
								</BaseButton>
							</div>
						</div>
					</div>
				</div>
			</section>

			<SectionTags
				v-if="program.program_page_tags && program.program_page_tags.length"
				:tags="program.program_page_tags"
			/>

			<section
				v-if="program.about_program"
				class="section section-about"
			>
				<div class="content">
					<div class="about">
						<div class="about__content">
							<h2 class="title-section">
								О программе
							</h2>
							<div
								class="about__text"
								v-html="program.about_program"
							/>
						</div>
						<div class="about__pic-wrap">
							<img
								src="https://ea5ec095-4ab2-4f77-811e-49e733f2acc8.selcdn.net/static_video/pic__racoon-4--animated--v2.gif"
								alt="Енот"
								class="about__pic"
							>
						</div>
					</div>
				</div>
			</section>

			<SectionAudience
				v-if="program.for_whom_blocks && program.for_whom_blocks.length"
				:blocks="program.for_whom_blocks"
			/>

			<SectionSkills
				v-if="program.what_you_will_learn_blocks && program.what_you_will_learn_blocks.length"
				:blocks="program.what_you_will_learn_blocks"
			/>

			<SectionVideo
				v-if="program.program_page_video"
				:url="program.program_page_video"
			/>

			<SectionImages
				v-if="programSliderImages"
				:images="programSliderImages"
			/>

			<section
				v-if="program.partners.length"
				class="section section-brands"
			>
				<div class="content">
					<div class="brands">
						<VueSlickCarousel
							v-bind="brandsCarouselSettings"
							class="brands__slider"
						>
							<div
								v-for="(partner, index) in program.partners"
								:key="index"
							>
								<div class="brand">
									<!--									<div class="brand__title">-->
									<!--										Главный партнер-->
									<!--									</div>-->
									<img
										:src="partner.image_partner_course[0].image"
										alt="PartnerLogo"
										class="brand__logo"
									>
								</div>
							</div>
						</VueSlickCarousel>
					</div>
				</div>
			</section>

			<section
				id="program"
				class="section section-modules"
			>
				<div class="content">
					<h2 class="title-section">
						Содержание программы
					</h2>
					<div class="modules-number">
						{{
							program.modules.length
								| toPlural("модуль", "модуля", "модулей")
						}}:
					</div>
				</div>
				<div class="modules">
					<Module
						v-for="module in program.modules"
						:key="module.id"
						:module="module"
					/>
				</div>
			</section>

			<SectionCertificate
				v-if="program.show_certificate_in_the_course_page"
			/>

			<section
				v-if="program.experts.length"
				class="section section-experts"
			>
				<div class="content">
					<div class="slider-wrap">
						<VueSlickCarousel
							class="experts-slider"
							v-bind="expertsCarouselSettings"
						>
							<div
								v-for="expert in program.experts"
								:key="expert.id"
								class="expert"
							>
								<div class="expert">
									<div
										class="expert__avatar"
										:style="expert.image_expert[0].image | bgrDynamicImageCover"
									/>
									<div class="expert__content">
										<div class="title-mono">
											ЭКСПЕРТ ПРОГРАММЫ
										</div>
										<h3 class="title-block">
											{{ expert.name }}
										</h3>
										<div class="expert__position">
											{{ expert.position }}
										</div>
									</div>
								</div>
							</div>
						</VueSlickCarousel>
					</div>
				</div>
			</section>

			<section
				v-if="program.books.length"
				class="section section-books"
			>
				<div class="content">
					<h2 class="title-section">
						Рекомендованные книги
					</h2>
					<div class="books">
						<div
							v-for="book in program.books"
							:key="book.id"
							class="book"
						>
							{{ book.name }}
						</div>
					</div>
				</div>
			</section>

			<section
				v-if="programs.length"
				class="section section-courses"
			>
				<div class="content">
					<h2 class="title-section">
						Еще программы по теме
					</h2>
					<div class="slider-wrap">
						<VueSlickCarousel
							v-bind="carouselSettings"
							:dots="false"
							class="courses-slider"
						>
							<div
								v-for="course in programs"
								:key="course.name"
							>
								<div
									class="course"
									:class="{'course--finished': course.progress_percent === 100}"
								>
									<div class="course__header">
										<router-link
											class="course__title"
											:to="`/courses/program/${course.slug}/${course.id}`"
											target="_blank"
										>
											{{ course.name }}
										</router-link>
										<div
											class="course__text"
											v-html="course.description"
										/>
									</div>
									<div class="course__footer">
										<div
											v-if="course.progress === 100"
											class="course__status"
										>
											<img
												:src="'/img/icons/icon__plus-circle--white.svg' | prodSrcImage"
												alt="Иконка"
											>
											Программа пройдена
										</div>
										<div
											v-else-if="course.progress < 100 && course.progress >= 0 && course.progress !== null"
											class="progress-course"
										>
											<div class="progress-course__track">
												<div
													class="progress-course__line"
													:style="{'width': + course.progress_percent + '%'}"
												/>
											</div>
										</div>
										<div
											v-else
											class="course__duration"
										>
											{{ course.duration | durationFilter }}
										</div>
										<div class="course__info">
											<router-link
												:to="`/courses/program/${course.slug}/${course.id}`"
												target="_blank"
												class="course__link"
											>
												О программе
											</router-link>
											<div class="course__members">
												{{ course.total_listeners }}
											</div>
										</div>
									</div>
								</div>
							</div>
						</VueSlickCarousel>
					</div>
				</div>
			</section>

			<section
				v-if="!isLogged"
				class="section section-action"
			>
				<div class="content">
					<div class="action">
						<div class="action__text">
							Регистрируйся на нашей платформе и получи доступ к этим и многим другим бесплатным программам, конкурсам и проектам по профориентации.
						</div>
						<button class="button">
							Зарегистрироваться
						</button>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Breadcrumb from '@/components/Breadcrumb';
import BaseButton from '@/components/BaseComponents/BaseButton';
import Module from './module';
import SectionTags from '@/components/learning/SectionTags';
import SectionSkills from '@/components/learning/SectionSkills';
import SectionCertificate from '@/components/learning/SectionCertificate';
import SectionAudience from '@/components/learning/SectionAudience';
import SectionVideo from '@/components/learning/SectionVideo';
import SectionImages from '@/components/learning/SectionImages';
export default {
	components: {
		Module,
		Breadcrumb,
		BaseButton,
		SectionTags,
		SectionSkills,
		SectionCertificate,
		SectionAudience,
		SectionVideo,
		SectionImages,
	},
	data() {
		return {
			isLoadingRegistred: false,
			isLoaded: false,
            brandsCarouselSettings: {
                infinite: false,
                easing: 'swing',
                arrows: true,
                dots: false,
                autoplay: false,
                autoplaySpeed: 3000,
                pauseOnFocus: false,
                pauseOnHover: false,
                speed: 600,
                draggable: false,
                slidesToShow: 7,
                slidesToScroll: 1,
                adaptiveHeight: true,
                responsive: [
                    {
                        breakpoint: 1400,
                        settings: {
                            slidesToShow: 6,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 5,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 1100,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 1000,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 800,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            },
            expertsCarouselSettings: {
                infinite: false,
                easing: 'swing',
                arrows: true,
                dots: false,
                autoplay: false,
                autoplaySpeed: 3000,
                pauseOnFocus: true,
                pauseOnHover: false,
                speed: 600,
                draggable: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                adaptiveHeight: false
            },
		};
	},
	computed: {
		...mapGetters('tb', {
			program: 'getProgram',
			programs: 'getPrograms'
		}),
		...mapGetters('user', {
			isLogged: 'isLoggedIn',
			user: 'userInfo'
		}),
		directions() {
			let result = this.program.direction.map(dir => {
				return dir;
			});
			return result.join();
		},
		linkAllPrograms() {
			let status =
				this.program.status === 'completed' ? 'completed' : 'active';
			if (IS_DEV || IS_TEST) {
				return `https://go.teachbase.ru/course_sessions/${status}`;
			} else {
				return `https://lk.lift-bf.ru/course_sessions/${status}`;
			}
		},
		isStarted() {
			if (this.course) {
				return !!(
					this.user &&
					this.user.req_course_user &&
					this.user.req_course_user.some(
						req_course => req_course.course === this.course.id
					)
				);
			}
			return false;
		},
		buttonSendText() {
			if (this.program.participant) {
				return 'Продолжить изучение';
			} else if (this.isLogged && !this.program.participant) {
				return 'Записаться на программу';
			} else {
				return 'Зарегистрироваться';
			}
		},
		programSliderImages() {
			return this.program?.image_program
				?.filter(image => image.type === 'program_slider')
				.reduce((a, b) => [...a, {url: b.image}], []);
		}
	},
	$route: {
		handler(route) {
			if (route.name === 'Course') {
				let directions = this.program.direction.map(dir => {
					return dir;
				});
				directions.join();
				this.program && this.getProgram(this.$route.params.slug);
				!this.program && this.getAllPrograms(directions);
				setTimeout(
					() =>
						this.$scrollTo(document.querySelector('header.header-role')),
					0
				);
			}
		}
	},
	watch: {
		$route: {
			handler() {
				this.isLoaded = false;
				this.$scrollTo(document.querySelector('header.header-role'), 900);
				this.getProgram(this.$route.params.slug).then(() => {
					this.getAllPrograms(this.directions);
					this.isLoaded = true;
				});
			}
		}
	},
	created() {
		this.getProgram(this.$route.params.slug).then(() => {
			this.isLoaded = true;
			let directions = this.program.direction.map(dir => {
				return dir;
			});
			directions.join();
			this.getAllPrograms(directions);
		});
	},
	mounted() {
		this.$scrollTo(document.querySelector('header.header-role'), 900);
	},
	destroyed() {
		this.isLoaded = false;
	},
	methods: {
		...mapActions('tb', ['startProgram', 'getProgram', 'getAllPrograms']),
		isStartedProgram(program) {
			let result =
				program.status === 'waiting' ||
				program.status === 'in_progress' ||
				program.status === 'completed';
			return result;
		},
		courseBgrImage(image) {
			if (image) return `background-image: url(${image});`;
			else
				return 'background-image: url(https://lift-bf.ru/staticfiles/img/landscape-image.png);';
		},
		goToProgram(program) {
			window.location.href = `/courses/program/${program.slug}/${program.id}`;
		},
        start() {
            if (!this.isLogged) {
                this.modalOpen('regIn');
                return;
            } else if (this.isLogged && !this.program.participant) {
                this.isLoadingRegistred = true;
                this.startProgram({
                    program_id: this.program.out_program_id,
                    session_id: this.program.out_session_id,
                    user_tb_id: this.user.out_id
                }).then(() => {
                    setTimeout(() => {
                        this.isLoadingRegistred = false;
                        this.getProgram(this.$route.params.slug);
                    }, 2000);
                    this.$scrollTo(document.querySelector('#program'));
                })
            } else {
                this.$scrollTo(document.querySelector('#program'));
            }
        }
	}
};
</script>

<style lang="less">
@import "../../assets/styles/pages/course";

.course-card__stats {
	display: flex;
	.stat {
		display: flex;
	}
}
.slick-arrow.slick-next {
	top: 50%;
	right: -50px;
}
.slick-arrow.slick-prev {
	top: 50%;
	left: -50px;
}
.breadcrumbs {
	li {
		&:last-child {
			color: white;
		}
	}
}
.partner {
	pointer-events: none;
}
.partner--active {
	pointer-events: initial;
}
.preloader-wrap {
	position: relative;
}
.button-preloader-icons {
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>
