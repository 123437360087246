<template>
	<div :class="['module',{'module--open':isShow}]">
		<div class="content">
			<div class="module__body">
				<div class="module__header">
					<div
						:class="['module__title', {'module__title--open':isShow}]"
						@click="openModule"
					>
						{{ module.name }}
					</div>
				</div>
				<slide-up-down
					:active="isShow"
					:duration="600"
				>
					<div
						class="module__list"
					>
						<Course
							v-for="course in module.courses"
							:key="course.id"
							:course="course"
						/>
					</div>
				</slide-up-down>
			</div>
		</div>
	</div>
</template>

<script>
import Course from './course';
export default {
	components: {
		Course
	},
	props: {
		module: {
			type: Object,
			default: () => {}
		}
	},
	data() {
		return {
			isShow: false,
			statusFinished: [],
			isFinishedCourses:false,
		};
	},
	computed: {
		isFinished() {
			return this.module.courses.every((course) => course.progress === 100)
		}
	},
  methods: {
    openModule() {
      this.isShow = !this.isShow
    }
  }
};
</script>
